<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary ml-1">
            Pagsmile
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Welcome to <br>
          Pagsmile Admin Dashboard v2.0! 👋
        </b-card-title>
        <b-card-text class="mb-2">
          Please sign-in to your account and start use
        </b-card-text>

        <!-- form -->
        <b-form
          class="auth-login-form mt-2"
          @submit.prevent="login"
        >

          <b-tabs v-model="tabActived" align="left">
            <b-tab active title="Google Auth">
              <b-form-group
                label-for="any-account"
                label="Account"
              >
                <b-form-input
                  id="any-account"
                  v-model="accountName"
                  name="any-account"
                  autofocus
                />
              </b-form-group>
              <b-form-group
                label-for="google-code"
                label="Google Authentication Code"
              >
                <b-form-input
                  id="google-code"
                  v-model="googleAuthenticationCode"
                  type="number"
                  name="google-code"
                  autofocus
                />
              </b-form-group>
              <b-form-group
                v-if="qrCodeImg != ''"
                label-for="qrCode"
                label="QR Code"
              >
                <b-img center height="200" :src="qrCodeImg" alt="QR Code" />
              </b-form-group>
            </b-tab>
            <b-tab v-if="!isProd" title="E-Mail">
              <!-- email -->
              <b-form-group
                label-for="email"
                label="Email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  autofocus
                />
              </b-form-group>
            </b-tab>
            <b-tab v-if="!isProd" title="Mobile">
              <b-form-group
                label="Phone Number"
                label-for="area-code"
              >
                <VuePhoneNumberInput
                  v-model="mobile"
                  :preferred-countries="['BR', 'CN', 'MX']"
                  default-country-code="BR"
                  @update="mobileUpdated"
                />
              </b-form-group>
              <b-form-group
                label="Verification Code"
              >
                <b-input-group>
                  <b-form-input
                    v-model="verificationCode"
                    type="number"
                    placeholder="Verification Code"
                  />
                  <b-input-group-append>
                    <b-button variant="outline-primary" :disabled="!mobileDetail.isValid || countdown > 0" @click="sendVerificationCode">
                      {{ countdown > 0 ? `${countdown}s` : 'Send' }}
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-tab>
          </b-tabs>

          <!-- password -->
          <b-form-group v-if="tabActived !== 2">
            <div class="d-flex justify-content-between">
              <label for="password">Password</label>
            </div>
            <b-input-group class="input-group-merge">
              <b-form-input
                id="password"
                v-model="password"
                :type="passwordFieldType"
                class="form-control-merge"
                name="login-password"
                placeholder="Password"
              />

              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <!-- submit button -->
          <b-button
            variant="primary"
            type="submit"
            block
            :disabled="submitDisabled"
          >
            {{ submit }}
          </b-button>
        </b-form>

        <b-card-text class="text-center mt-2">
          <span>
            Need a new/private account? <br>
            Please contact THE administrator.
          </span>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BTabs, BTab, BImg,
} from 'bootstrap-vue'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import useJwt from '@/auth/jwt/useJwt'
import { showToast } from '@/libs/tool'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import useAuthenticationJs from './authentication'

const {
  sendVerificationCode,
} = useAuthenticationJs()

export default {
  components: {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BTabs,
    BTab,
    BImg,
    VuePhoneNumberInput,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isProd: window.location.host === 'admin-dashboard.pagsmile.com',
      intervalId: '',
      tabActived: 0,
      mobile: '',
      mobileDetail: {},
      countdown: 0,
      verificationCode: '',
      userEmail: '',
      password: '',
      status: '',
      submit: 'Sign In',
      isLogging: false,
      accountName: '',
      googleAuthenticationCode: '',
      qrCodeImg: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    submitDisabled() {
      return this.isLogging || (this.tabActived === 2 && (this.mobile === '' || this.verificationCode === '')) || (this.tabActived === 1 && this.userEmail === '')
    },
  },
  methods: {
    showLogging() {
      this.submit = 'Logging In'
      this.isLogging = true
    },
    hideLogging() {
      this.submit = 'Sign In'
      this.isLogging = false
    },
    mobileUpdated(value) {
      this.mobileDetail = value
    },
    timer() {
      clearInterval(this.intervalId)
      this.intervalId = setInterval(() => {
        this.countdown -= 1
      }, 1000)
    },
    sendVerificationCode() {
      sendVerificationCode(
        `${this.mobileDetail.countryCallingCode}${this.mobileDetail.nationalNumber}`,
        () => {
          showToast(this, 'success', 'Sended')
          this.countdown = 60
          this.timer()
        },
        fail => {
          showToast(this, 'Warning', `Send Verification Code Fail With ${fail}.`)
        },
      )
    },
    login() {
      this.showLogging()
      let username = ''
      let password = ''
      let verificationCode = ''
      let type = ''
      switch (this.tabActived) {
        case 0:
          username = this.accountName
          password = this.password
          type = 'google'
          verificationCode = this.googleAuthenticationCode
          break
        case 1:
          username = this.userEmail
          password = this.password
          type = 'email'
          break
        case 2:
          username = `${this.mobileDetail.countryCallingCode}${this.mobileDetail.nationalNumber}`
          password = this.verificationCode
          type = 'mobile'
          break
        default:
          return
      }

      useJwt.login({
        username,
        password,
        type,
        verification_code: verificationCode,
      })
        .then(response => {
          this.hideLogging()
          if (response.data.code !== '10000') {
            showToast(this, 'Warning', `${response.data.msg}`)
            return
          }
          const {
            access_token, refresh_token, user_data, detail, img,
          } = response.data.data
          if (img) {
            this.qrCodeImg = img
            return
          }
          useJwt.setToken(access_token)
          useJwt.setRefreshToken(refresh_token)

          if (detail) {
            const {
              realName, nickname, permissions, avatar, roles,
            } = detail
            user_data.realName = realName
            user_data.nickname = nickname
            user_data.avatar = avatar
            user_data.roles = roles
            if (permissions) {
              const initialAbility = {
                subject: 'Auth',
                action: ['read'],
              }
              const dashboard = {
                subject: 'Dashboard',
                action: ['read'],
              }
              user_data.ability = JSON.parse(permissions)
              user_data.ability.push(initialAbility)
              user_data.ability.push(dashboard)
              this.$ability.update(user_data.ability)
            }
          }
          user_data.loginName = this.userEmail

          localStorage.setItem('userData', JSON.stringify(user_data))
          // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
          this.$router.replace('/')
            .then(() => {
              showToast(this, 'success', `Welcome ${this.userEmail}! You have successfully logged in as ${user_data.userRole}. Now you can start to explore!`)
            })
        })
        .catch(fail => {
          this.hideLogging()
          showToast(this, 'Warning', `${fail}.`)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
